import {
    createWebHistory,
    createRouter
} from 'vue-router';
import TextToTermsView from '@/views/TextToTermsView.vue'
import AboutView from '@/views/AboutView.vue'
import MultipleTextsView from '@/views/MultipleTextsView'
import SingleTextView from '@/views/SingleTextView'

const routes = [{
        path: '/',
        name: 'Home',
        component: TextToTermsView,
    },
    {
        path: '/about',
        name: 'About',
        component: AboutView,
    },
    {
        path: '/texts',
        name: 'Texts',
        component: MultipleTextsView
    },
    {
        path: '/texts/:id',
        name: 'SingleText',
        component: SingleTextView
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;