<template>
    <Teleport to="body">
        <div v-if="modalIsOpenProp" class="modal">
            <div class="modal-items-container m-auto border rounded">
                <p>{{message}}</p>
                <div class="confirm-cancel-button-container">
                    <button class="btn btn-primary mr-2" @click="emitConfirmEvent">{{confirmButtonText}}</button>
                    <button class="btn btn-secondary" @click="emitCancelEvent">{{cancelButtonText}}</button>
                </div>
            </div>

        </div>
    </Teleport>
</template>


<script>
export default {
    props: ['confirmEvent', 'cancelEvent', 'confirmButtonText', 'cancelButtonText', 'message', 'modalIsOpenProp'],
    name: 'SimpleModal',
    emits: ['newUserText', 'keepUserText'],
    methods: {
        emitConfirmEvent() {
            this.$emit(this.confirmEvent)
        },
        emitCancelEvent() {
            this.$emit(this.cancelEvent)
        }
    }
}
</script>


<style scoped>
.modal {
    position: fixed;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}
.confirm-cancel-button-container {
    display: flex;
}
.modal-items-container {
    background: rgb(208, 252, 232);
    padding: 2rem;
}
</style>