<script setup>
</script>

<template>
<head>
    <meta charset="UTF-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />


</head>
<nav class="navbar sticky-top p-3">
        <div class="header-items row hidden-mobile">
            <div class="d-flex ml-5">
                <router-link to="/">
                    <img src="./assets/arnamagn_logo.svg" class="mr-4">
                </router-link>
            </div>
            <div class="d-inline">

                    <h2 class="mb-0"><router-link to="/" class="main-link-header">TermPortal</router-link></h2>
                <span class="main-link-header" href="https://arnastofnun.is">Stofnun Árna Magnússonar í íslenskum fræðum</span>
            </div>
        </div>
    <div class="header-items row hidden-mobile-up">
        <div class="col-4 d-flex">
            <img src="./assets/arnamagn_logo.svg" class="mr-4 mobile-header-image">
        </div>
        <div class="col-8 d-flex justify-content-center">
            <a class="navbar-brand mr-auto my-auto" href="#">TermPortal</a>
        </div>
    </div>

    <div class="dropdown mr-5">
        <button class="btn dropdown-button dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
            ≡
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <router-link class="dropdown-item" :to="{ name: 'Home'}">Heim</router-link>
            <router-link class="dropdown-item" :to="{ name: 'Texts'}">Textadæmi</router-link>
            <router-link class="dropdown-item" :to="{ name: 'About'}">Um TermPortal</router-link>
        </div>
</div>

</nav>

<body class="w-100">
    <main class="container-fluid mb-auto mt-5">
        <router-view :key="$route.fullPath"></router-view>
    </main>
</body>
</template>

<style>
@import './assets/base.css';
</style>
