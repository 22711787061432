import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import router from './router/index.js'



// import VueAxios from 'vue-axios'
import App from './App.vue'
const app = createApp(App).use(router)
const base = axios.create({baseURL: 'http://127.0.0.1:8000/api/'})
app.config.globalProperties.$http = base;

app.use(createPinia())
// app.use(router)
app.mount('#app')
