<template>
  <div class="mx-auto col-md-9">

    <div class="texts-container border mb-3 rounded p-5">
        <div v-for="(text, index) in userTexts" :key="index">
            <div class="single-text-container">
                <router-link class="single-text-link" :to="{ name: 'SingleText', params: {'id': text.id} }">
                    <p class="border rounded p-2 mb-2 keyterm-text">{{text.text}}</p>
                    <div class="accepted-terms-view border rounded d-flex mb-2">
                        <p class="key-terms">{{concatArray(text.acceptedTerms)}}</p>
                    </div>
                    <p class="border rounded p-2 text-field keyterm-text">{{text.field}}</p>
                    <hr class="mt-0 mb-3 text-separator"/>
                </router-link>
            </div>
        </div>
    </div>

    <div class="view-buttons mr-auto mb-3">
        <button type="button"
                name="button"
                class="btn btn-info view-button mr-2"
                @click="getTexts(this.previousPage)"
                v-bind:class="previousPage ? 'none': 'disabled-click'">
                    Fyrri
        </button>
        <button type="button"
                name="button"
                class="btn btn-info view-button"
                @click="getTexts(this.nextPage)"
                v-bind:class="nextPage ? 'none': 'disabled-click'">
                    Næstu
        </button>
    </div>
</div>
</template>

<script>
export default {
    name: 'MultipleTextsView',
    data() {
        return {
            userTexts: [],
            offset: 0,
            nextPage: null,
            previousPage: null,
            initUrl: 'user-text/'
        }
    },
    mounted() {
        this.getTexts(this.initUrl)
    },
    methods: {
        getTexts(url) {
            this.userTexts = []
            let headers = {
                'Content-Type': 'application/json'
            }
            this.$http.get(url, {'headers': headers}).then((response) => {
                this.nextPage = response.data.next
                this.previousPage = response.data.previous
                for (let userText of response.data.results) {
                    let currName = 'Engin faggrein'
                    if (userText.field.descriptive_name) {
                        currName = userText.field.descriptive_name
                    }
                    this.userTexts.push({'text': userText.text, 'acceptedTerms': userText.confirmed_terms, 'id': userText.id, 'field': currName})
                }
            }).catch((error) => {
                alert(error)
            })
        },
        concatArray(objectOfTerms) {
            let terms = ''
            for (let term of objectOfTerms) {
                terms += `${term.term}, `
            }
            return terms.slice(0, -2)
        }
    }
}
</script>

<style scoped>
.about-container {
    color: rgb(83, 83, 83);
}
.view-button {
    margin-right: 0.6rem;
    width: 8rem;
}
.key-terms {
    font-size: 0.8rem;
}
.texts-container {
    height: 75vh;
    max-height: 75vh;
    overflow-y: scroll;
    background-color: rgb(237, 237, 237)
}
.disabled-click {
    pointer-events: none;
    background-color: rgb(130, 130, 130);
    border-color: rgb(115, 115, 115);
}
.keyterm-text {
    background-color: rgba(255, 255, 255, .32);
}
.accepted-terms-view {
    background: rgba(255, 255, 255, .32);
    padding: 0.4rem;
}
.texts-container div > a {
    color: rgb(83, 83, 83);
    text-decoration: none;
}
.single-text-container:hover .keyterm-text {
    background-color: white;
}

.single-text-container:hover .accepted-terms-view {
    background-color: white;
}
.text-field {
    width: fit-content;
    font-size: 0.8rem;
}
.text-separator {
    border: 1px solid rgb(186, 186, 186);
    border-radius: 5px;
}
</style>