<template>
    <div class="container w-100 d-flex flex-column align-items-center">
        <div class="wip mr-auto">
            <p style="font-weight:bolder;text-decoration:underline;">TermPortal er enn í vinnslu. Hér er hægt að skoða grunnvirkni þess en breytingar á því kunna að verða gerðar án fyrirvara.</p>
        </div>
        <div class="view-buttons mr-auto mb-3">
            <button type="button"
                    name="button"
                    class="btn btn-info view-button"
                    v-bind:class="{ active: viewMode === 'text' }"
                    @click="changeView('text')">
                        Texti
            </button>
            <button type="button"
                    name="button"
                    class="btn btn-info view-button"
                    v-bind:class="{ active: viewMode === 'terms' }"
                    @click="changeView('terms')">
                        Íðorð
            </button>
            <button type="button"
                    name="button"
                    class="btn btn-info view-button"
                    v-bind:class="{ active: viewMode === 'all' }"
                    @click="changeView('all')">
                        Allt
            </button>
        </div>

        <div id="text-from-user-container" class="w-100 row" v-if="viewMode === 'text' || viewMode === 'all'">
            <textarea id="text-from-user" class="form-control" autofocus
                      v-model="userText"
                      placeholder="Límdu texta hér og smelltu á „Greina texta“ til þess að fá úr honum uppástungur um íðorð">
            </textarea>
            <div class="flex-column">

                <div class="user-text-controls d-flex mt-2">
                    <button type="button"
                            name="button"
                            class="btn btn-info view-button"
                            @click="postUserText()">
                                Greina
                    </button>
                    <div class="term-mode-buttons d-flex ml-2">
                        <div class="form-check my-auto ml-2">
                          <input class="form-check-input"
                                 @click="changeTermMode('singleTerm')"
                                 type="radio"
                                 name="flexRadioDefault"
                                 id="singleTermMode"
                                 :checked="termMode=='singleTerm' ? true : false">
                          <label class="form-check-label" for="singleTermMode">
                            Stakorð
                          </label>
                        </div>
                        <div class="form-check my-auto ml-2">
                          <input class="form-check-input"
                                 @click="changeTermMode('multiTerm')"
                                 type="radio"
                                 name="flexRadioDefault"
                                 id="multiTermMode"
                                 :checked="termMode=='multiTerm' ? true : false">
                          <label class="form-check-label" for="multiTermMode">
                            Fjölyrt
                          </label>
                        </div>
                    </div>
                </div>
                <div class="input-group mt-3 mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Faggrein</label>
                    </div>
                    <select class="custom-select" id="field-select" v-model="selectedField" title="Veldu faggrein sem textinn heyrir til, ef það á við.">
                        <option v-for="(field, index) in allFields"
                                :key="index"
                                :value="field.short_name">
                                    {{field.descriptive_name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="viewMode === 'terms' || viewMode === 'all'" class="w-100">
            <div v-if="dataReady && computedTerms.length > 0" class="term-candidates-container mt-4 container-fluid justify-content-center">
                    <p class="possible-terms-header">Möguleg íðorð úr texta</p>
                <div class="mt-3 mb-3 d-flex mx-auto border rounded" id="term-candidates">
                    <div v-for="(item, index) in computedTerms"
                         :key="index" class="row mt-2">
                        <div class="col-3 d-flex justify-content-center">
                            <button type="button" name="accept" class="btn btn-success" @click="acceptTerm(item.token.term)">
                                Samþykkja
                            </button>
                        </div>
                        <div class="col-6 d-flex justify-content-center">
                            <input class="inline align-middle my-auto px-5 term-cand term-input" v-model="item.token.term">
                        </div>
                        <div class="col-3 d-flex justify-content-center">
                            <button type="button" name="reject" class="btn btn-danger" @click="rejectTerm(item.token.term)">
                                Hafna
                            </button>
                        </div>
                     </div>
                </div>
                <div class="add-custom-term-container">
                    <div class="d-flex add-custom-term-toggle-div" @click="customTermBeingAdded = !customTermBeingAdded">
                        <div class="add-custom-term-button-container">
                            <p v-if="!customTermBeingAdded" class="add-custom-term-button blue-font" title="Bættu við eigin orðum">+</p>
                            <p v-if="customTermBeingAdded" class="add-custom-term-button red-font" title="Bættu við eigin orðum">-</p>
                        </div>
                        <p class="ml-2">Bættu við eigin orðum</p>
                    </div>
                    <div v-if="customTermBeingAdded" class="row mt-2">
                        <div class="col-3 d-flex justify-content-center">
                            <button type="button" name="accept" class="btn btn-success" @click="acceptTerm(customTerm)">
                                Senda
                            </button>
                        </div>
                        <div class="col-6 d-flex justify-content-center">
                            <input class="inline align-middle my-auto px-5 term-cand term-input" v-model="customTerm">
                        </div>
                        <div class="col-3 d-flex justify-content-center">
                            <button type="button" name="reject" class="btn btn-danger" @click="customTerm=''">
                                Hreinsa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!dataReady && computedTerms.length <= 0 && !loading" class="d-flex mt-2">
                <div class="info-exclamation-container">
                    <p class="info-exclamation-mark">!</p>
                </div>
                <p class="no-terms mr-auto">Límdu texta í textaboxið og smelltu á „Greina texta“ til þess að fá úr honum möguleg íðorð</p>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <SimpleModal :confirmEvent="'keep-user-text'"
                 :cancelEvent="'new-user-text'"
                 :message="'Þú hefur breytt textanum. Viltu halda áfram með breytta útgáfu upprunalega textans eða búa til nýja færslu í kerfinu?'"
                 :confirmButtonText="'Halda áfram'"
                 :cancelButtonText="'Ný færsla'"
                 :modalIsOpenProp="textChanged"
                 @keep-user-text="handleTextChange('keep-text')"
                 @new-user-text="handleTextChange('new-text')">
    </SimpleModal>

</template>

<script>
import SimpleModal from './SimpleModal'
export default {
    components: {SimpleModal},
    name: 'TextToTerms',
    data() {
        return {
            userText: null,
            termsFromText: [],
            acceptedTerms: [],
            rejectedTerms: [],
            userTextId: null,
            termMode: localStorage.getItem('termMode') ? localStorage.getItem('termMode') : 'singleTerm',
            viewMode: 'text',
            loading: false,
            customTerm: '',
            customTermBeingAdded: false,
            textChanged: false,
            allFields: [],
            selectedField: ''
        }
    },
    mounted() {
        this.getFields()
    },
    methods: {
        postUserText() {
            this.loading = true
            let headers = {
                'Content-Type': 'application/json'
            }
            let postUrl = 'user-text/'
            // This block is only for when a user has already posted their text and updates it
            if (this.userTextId) {
                postUrl += `${this.userTextId.toString()}/`
                let data = {
                    text: this.userText,
                    mode: this.termMode
                }
                if (this.selectedField) {
                    data.field = {'short_name': this.selectedField}
                }
                this.$http.patch(postUrl, data, {'headers': headers}).then((response) => {
                    this.loading = false
                    this.userTextId = response.data.id
                    this.termsFromText = []
                    for (let [index, token] of response.data.suggested_terms.entries()) {
                        this.termsFromText.push({'token': token, 'index': index})
                    }
                    this.viewMode = 'terms'
                }).catch((error) => {
                    alert('Villa kom upp. Vinsamlegast endurhlaðið síðuna.')
                    this.loading = false
                    return error
                })
            } else {
                let data = {
                    text: this.userText,
                    mode: this.termMode
                }
                if (this.selectedField) {
                    data.field = {'short_name': this.selectedField}
                }
                this.$http.post(postUrl, data, {'headers': headers}).then((response) => {
                    this.loading = false
                    this.userTextId = response.data.id
                    this.termsFromText = []
                    for (let [index, token] of response.data.suggested_terms.entries()) {
                        this.termsFromText.push({'token': token, 'index': index})
                    }
                    this.viewMode = 'terms'
                }).catch((error) => {
                    alert('Villa kom upp. Vinsamlegast endurhlaðið síðuna.')
                    this.loading = false
                    return error
                })
            }
        },
        acceptTerm(term) {
            this.acceptedTerms.push(term)
            let data = {'terms': [{'term': term}], 'action': 'accept'}
            this.$http.patch(`user-text/${this.userTextId}/`, data).then(() => {
                if (this.customTermBeingAdded) {
                    this.customTerm = ''
                }
            })
        },
        rejectTerm(term) {
            this.rejectedTerms.push(term)
            let data = {'terms': [{'term': term}], 'action': 'reject'}
            this.$http.patch(`user-text/${this.userTextId}/`, data).then(() => {
                if (this.customTermBeingAdded) {
                    this.customTerm = ''
                }
            })
        },
        changeView(view) {
            this.viewMode = view
        },
        changeTermMode(mode) {
            this.termMode = mode
            localStorage.setItem('termMode', mode)
        },
        handleTextChange(event) {
            if (event === 'keep-text') {
                this.textChanged = false
                return
            } else if (event === 'new-text') {
                this.userTextId = null
                this.textChanged = false
            }
        },
        getFields() {
            this.$http.get('field/').then((response) => {
                let fields = response.data.results
                this.allFields = fields.sort((a, b) => a.descriptive_name.localeCompare(b.descriptive_name))
            })
        }
    },
    computed: {
        dataReady() {
            return this.termsFromText.length > 0
        },
        computedTerms() {
            if (this.termsFromText.length > 0) {
                return this.termsFromText.filter(element =>
                    !this.acceptedTerms.includes(element.token.term) &&
                    !this.rejectedTerms.includes(element.token.term))
            } else {
                return []
            }
        }
    },
    watch: {
        userText() {
            if (this.dataReady && this.userTextId) {
                this.textChanged = true
            }
        }
    }
}
</script>

<style lang="css" scoped>
@import '/src/assets/base.css';
#text-from-user {
    height: 33vh;
    width: 100%;
}
#term-candidates {
    height: 50vw;
    max-height: 33vh;
    overflow: scroll;
    padding: 2rem;
    flex-direction: column;
    width: 100%;
}
#text-from-user-container {
}
.term-candidates-container {
    display: flex;
    flex-direction: column;
}

.term-cand {
    width: 100%;
}
.term-input {
    text-align: center;
}
.view-button {
    margin-right: 0.6rem;
    width: 8rem;
}
.active {
    background: #083f49 !important;
    color: white;
}
.info-exclamation-container {
    background: white;
    border: 0.1rem solid var(--mainColor);
    color: var(--mainColor);
    font-weight: bolder;
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    text-align: center;
}

.info-exclamation-mark {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.no-terms {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 0.4rem;
}
.possible-terms-header {
    font-size: 1.5rem;
    font-weight: bold;
}
.spinner-border {
    color: var(--mainColor);
}
.add-custom-term-button-container {
    background: white;
    color: var(--mainColor);
    font-weight: bolder;
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    text-align: center;
    cursor: pointer;
    background: rgb(223, 223, 223);
}
.add-custom-term-button {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}
.add-custom-term-container {
    padding: 2rem;
}
.add-custom-term-toggle-div {
    cursor: pointer;
    width: fit-content;
}
.blue-font {
    color: rgb(30, 108, 199);
}
.red-font {
    color: rgb(199, 30, 30);
}
</style>
