<template>
  <div class="about-container mx-auto">
    <SingleText></SingleText>
</div>
</template>

<script>
import SingleText from '@/components/SingleText.vue'
export default {
    components: {SingleText},
    name: 'SingleTextView',

}
</script>
<style scoped>
.about-container {
    color: rgb(83, 83, 83);
}
</style>