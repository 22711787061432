<template>
  <div class="about-container mx-auto col-md-4">
    <h1>TermPortal</h1>
    <p>
        TermPortal er íðorðatökutól sem hannað var og þróað af Hjalta Daníelssyni í samvinnu við Ágústu Þorbergsdóttur, síðar meir með aðkomu Gunnars Thors Örnólfssonar. Tólinu er haldið við og það þróað af Atla Jasonarsyni.
    </p>
    <p>
        TermPortal má nota til þess að hlaða upp texta og fá úr honum uppástungur um fagorð sem í honum kunna að leynast, hvort sem þau eru einyrt (t.d. „samnafn“) eða fleiryrt (t.d. „samnafn dregið af sérnafni“).
    </p>
    <p>
        Tólið er í umsjón og á ábyrgð Atla Jasonarsonar hjá Árnastofnun.
    </p>
    <p>
        <router-link :to="{name: 'Home'}">Smellið hér til að prófa TermPortal.</router-link>
    </p>
</div>
</template>

<script>
export default {
    name: 'AboutView'
}
</script>
<style scoped>
.about-container {
    color: rgb(83, 83, 83);
}
</style>