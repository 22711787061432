<template>
    <div class="container p-2">
        <div class="texts-container">
            <p class="border rounded p-2 mb-3 keyterm-text">{{text}}</p>
            <div class="terms-view">
                <div class="keyterm-container">
                    <p class="mb-0 term-header">Samþykkt fagorð</p>
                    <div class="keyterms">
                        <div v-for="(term, index) in acceptedTerms" :key="index" class="single-keyterm border rounded accepted-term">
                            <p>{{term.term}}</p>
                        </div>
                    </div>
                </div>
                <div class="keyterm-container">
                    <p class="mb-0 term-header">Fagorð sem hefur verið hafnað</p>
                    <div class="keyterms">
                        <div v-for="(term, index) in rejectedTerms" :key="index" class="single-keyterm border rounded rejected-term">
                            <p>{{term.term}}</p>
                        </div>
                    </div>
                </div>
                <div class="keyterm-container">
                    <p class="mb-0 term-header">Uppástungur</p>
                    <div class="keyterms">
                        <div v-for="(term, index) in suggestedTerms" :key="index" class="single-keyterm border rounded suggested-term">
                            <p>{{term.term}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SingleText',
    data() {
        return {
            textId: this.$route.params.id,
            text: '',
            suggestedTerms: [],
            acceptedTerms: [],
            rejectedTerms: [],
            field: ''
        }
    },
    mounted() {
        this.getText()
    },
    methods: {
        getText() {
            this.userTexts = []
            let headers = {
                'Content-Type': 'application/json'
            }
            this.$http.get(`/user-text/${this.textId}`, {
                'headers': headers
            }).then((response) => {
                let data = response.data
                this.text = data.text
                this.suggestedTerms = data.suggested_terms
                this.acceptedTerms = data.confirmed_terms
                this.rejectedTerms = data.rejected_terms
                this.field = data.field.descriptive_name
            }).catch((error) => {
                alert(error)
            })
        },
    }
}
</script>
<style scoped>
.about-container {
    color: rgb(83, 83, 83);
}
.keyterms {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 0.2rem;
    margin-bottom: 2rem;
}
.single-keyterm {
    height: 1.8rem;
    width: 10rem;
    justify-content: center;
    text-align: center;
    color: white;
}
.accepted-term {
    background: var(--mainColor);
}
.rejected-term {
    background: rgb(181, 23, 23);
}
.suggested-term {
    background: rgb(23, 157, 181);
}
.term-header {
    font-size: 1.5rem;
}
.keyterm-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

}
</style>