<template>
    <div class="home">

        <TextToTerms></TextToTerms>
    </div>
</template>

<script>
import TextToTerms from '@/components/TextToTerms.vue'
export default {
    name: 'TextToTermsView',
    components: {
        TextToTerms
    }
}
</script>